.more-stats-container {
  display: flex;
  margin-top: 5%;
  text-align: left;
  margin-right: 5%;
  margin-left: 5%;
}

.more-stats-title {
  width: 100%;
}

.more-stats-row {
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
}

.h2-title-stats {
  padding-bottom: 0;
  margin-bottom: 0;
}
