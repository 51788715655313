.FilterBar {
  background-color: #e1e1e1;
  padding: 1%;
  width: 100%;
  justify-content: left;
}

.title {
  align-items: center;
  padding-top: 2rem;
}
