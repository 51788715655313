.MenuContainer {
  padding: 0% 0 20% 0;
  margin: 0 5%;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  padding-top: 5%;
  align-items: center;
  justify-content: center;
}

.ButtonArrange {
  margin: auto;
}
