.NavContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 8% 5%;
  position: fixed;
  width: 100%;
  top: 1%;
  @media (min-width: 750px) {
    padding: 2% 5%;
  }
}

.TopContainer {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  color: white;
  width: 100%;
}

.EmptyItemsCont {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
