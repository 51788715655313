.createLink {
  color: var(--primary-color);
  font-size: medium;
  border-color: var(--primary-color);
}

.buttonHolder {
  margin-top: 20px;
  padding-bottom: 20px;
}
