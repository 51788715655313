.Dot {
  border-radius: 15px;
  background-color: red;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 45%;
}

.Text {
  color: white;
  padding-top: 5px;
}
