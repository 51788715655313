.tophold {
  display: "flex";
  padding-bottom: 1%;
}

.searchButton {
  background-color: var(--primary-color);
  color: white;
  height: 2pc;
  width: 4pc;
  text-align: center;
  border-radius: 0.5pc;
  align-self: center;
}

.textInput {
  height: 2pc;
  font-size: 1.5pc;
}
