.mod-main-conatiner {
  padding: 6% 5%;
  margin-bottom: 50%;
}

.mod-report-container {
  padding: 1% 5%;
  margin-bottom: 50%;
}

.internal-nav {
  height: 6vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.mod-main-flex {
  margin: 1% 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.mod-left {
  width: 45%;
}

.mod-image-container {
  padding: 1% 5%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.mod-image {
  width: 50%;
}

.mod-right {
  width: 50%;
}

.mod-report-scrollView {
  height: 70vh;
  width: 100%;
}

.mod-button {
  padding: 2% 5%;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  margin: 10px;
  font-size: medium;
}

.mod-button:hover {
  box-shadow: 0px 1px 10px grey;
}

.green {
  background-color: #43a047;
}

.red {
  background-color: #f44336;
}

.orange {
  background-color: var(--primary-color);
}

.mod-textarea {
  resize: vertical;
  min-height: 20vh;
  margin: 0 0 3% 0;
  padding: 2%;
}

.mod-files {
  padding: 2% 5%;
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
}

.mod-item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5%;
  border: 1px solid grey;
  border-radius: 20px;
  min-width: 80%;
}

.mod-content-image {
  width: 80%;
  border-radius: 10px;
}
