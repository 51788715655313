.SideBar {
  padding-top: 20%;
  margin-top: 15%;
  z-index: 6;
}

.PageContent {
  margin-bottom: 50%;
  padding-bottom: 50%;
}
