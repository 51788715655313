.filterBar {
  flex-direction: row;
  margin-left: 5%;
  padding-left: 5%;
  margin-right: 5%;
  padding-right: 5%;
  background-color: #e1e1e1;
  padding-bottom: 20px;
  border-radius: 10px;
  padding-top: 5%;
}

.filterIndividual {
  align-content: center;
}

.filterRows {
  border-radius: 10px;
  padding: 10px;
  flex-direction: "column";
  background-color: #e1e1e1;
}

.Header {
  width: 25%;
  display: flex;
  padding-bottom: 2%;
}

.ResetButton {
  margin-left: 5%;
  background-color: #ff6712;
  border: none;
  outline: none;
  border-radius: 20px;
  height: 40px;
  width: 10rem;
  color: white;
  font-size: medium;
}

.ResetButton :hover {
  cursor: pointer;
}
