@import url("../../index.css");

.mission-navbar {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 5vh;
  padding: 3% 10%;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  background-color: white;
  width: 100%;
}

.mission-navbar-header {
  padding: 0;
  margin: 0;
}

.mission-body-container {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-height: 50vh;
  padding: 1% 0;
  justify-content: center;
  text-align: center;
}

.mission-user-counter-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.user-counter {
  font-size: 4em;
  font-family: "OpenSans-Bold";
  margin: 0;
  padding: 0;
}

.user-counter-title {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  font-family: "OpenSans-Light";
}

.mission-data-containers-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2% 0;
  border-radius: 10px;
}

.mission-data-container {
  padding: 1%;
  min-width: 15vw;
  border-radius: 10px;
}
.mission-data-topic {
  margin: 0 10%;
}

.first-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.mission-title-text {
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  text-align: center;
}

.second-row {
  justify-content: center;
  text-align: center;
  padding: 10% 0;
}

.data-text {
  margin: 0;
  padding: 0;
  font-size: 2.8em;
}

.mission-data {
  margin: 5% 10%;
  text-align: center;
}

.navigationItem {
  min-width: 20vw;
  margin: 1% 1%;
  padding: 2%;
  border-radius: 10px;
  color: black;
  transition: all ease-in-out 0.2s;
  box-shadow: 0 1px 3px gray;
  align-items: center;
  flex-direction: column;
}

.navigationItem:hover {
  color: black;
  box-shadow: 0 3px 10px gray;
}
