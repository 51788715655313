.BigOrange {
  color: var(--primary-color);
  padding-top: 10%;
  text-align: center;
}

.LogOut {
  color: var(--primary-color);
  margin-left: 50%;
  margin-top: 10%;
  border: "none";
  background-color: "white";
  outline: "none";
  font-size: "1.2em";
  cursor: "pointer";
}
