.MessaegContainer {
  height: 100%;
  margin-bottom: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 10%;
}

.TextContent {
  color: orangered;
}
